import axios from 'axios';
import { redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
const cookies = new Cookies(null, { path: '/' });

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Accept': 'application/json',
    'Authorization': cookies.get('token') || ""
  }
});

client.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      // Clear any authentication tokens or session information if needed
      // Redirect to the login page
      cookies.remove('token');
      redirect('/');
    }
    return Promise.reject(error);
  }
);
//i want if return Response 401 status code, redirect to login page

export { client };